
              @import "@/assets/css/variables.scss";
            







































































































































































































































































































/deep/ .button-box {
  margin-top: 36px !important;
  margin-bottom: 48px !important;
}

/deep/ .agreeTnc-box {
  margin-bottom: 52px !important;
}

.deactivate-area {
  margin-top: 64px;
}

.eligible-des {
  font-size: 14px;
}
