
              @import "@/assets/css/variables.scss";
            
































































































.dialog-table-desc {
  color: $future-blue;
  margin-top: 24px;
  margin-bottom: 12px;
}
