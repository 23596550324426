
              @import "@/assets/css/variables.scss";
            





















































































































































































.voucher-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.voucher-title {
  padding-top: 12px;
  padding-bottom: 12px;
  margin-bottom: 24px;
  font-size: 25px;
  line-height: 36px;
  font-weight: 800;
  text-align: center;
}
.account-content {
  font-size: 16px;
  line-height: 32px;
  font-weight: 800;
}

.voucher-content {
  border-radius: 20px;
  background-color: #ecf5ff;
  .voucher-container {
    width: 100%;
    padding: 52px 44px 52px 44px;
    display: flex;
    justify-content: space-between;
  }

  .voucher-left {
    display: flex;
    flex-direction: column;
    max-width: 50%;
    justify-content: space-between;
  }
  .lot-table {
    margin-bottom: 24px;
  }
  /deep/ .el-table.head-gutter th {
    padding-left: 0;
    padding-right: 0;

    .cell {
      padding: 4px 24px 4px 24px;
    }
  }
  .lot-desc {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $future-blue;
  }

  .lot-redeem {
    font-size: 20px;
    font-weight: 800;
    color: $future-blue;
    text-transform: uppercase;
  }

  .voucher-right {
    min-width: 310px;
  }
  .voucher-item {
    display: block;
    border-radius: 16px;

    &.box-shadow {
      box-shadow: 0px 20px 23px -4px #a5c9ff;
    }
  }

  .redeem-btn {
    max-width: 220px;
  }

  &.mobile {
    .voucher-container {
      flex-direction: column;
      align-items: center;
      padding: 36px;

      &.active {
        flex-direction: column-reverse;

        .voucher-left + .voucher-right {
          margin-bottom: 40px;
          margin-top: 0px;
        }

        .voucher-left {
          align-items: center;
        }

        .redeem-btn {
          min-width: 220px;
        }
      }
    }

    .voucher-left + .voucher-right {
      margin-top: 24px;
    }

    .voucher-left {
      max-width: 100%;
      width: 100%;
    }

    .voucher-right {
      max-width: 100%;
      min-width: 100%;
      display: flex;
      justify-content: center;
    }

    .lot-redeem {
      margin-bottom: 24px;
      text-align: center;
    }
  }
}
